import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Background } from "../../common"
import styled from "styled-components"

export const Intro = () => {
  return (
    <Wrapper>
      <Background>
        <article>
          <h1>Welkom bij Croon & Co</h1>
          <h1>
            Lekkere wijnen, zo van de boer, voor een goede prijs en met een mooi
            verhaal.
          </h1>
          <AnchorLink href="#about">
            <button>Lees meer</button>
          </AnchorLink>
        </article>
      </Background>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  article {
    width: 85vw;
    max-width: 800px;
    color: var(--clr-white);
    text-align: center;
    padding: 0;
    margin: 0;
    @media (max-width: 700px) {
      width: 85vw;
      max-width: 700px;
    }
    @media (max-width: 500px) {
      width: 85vw;
      max-width: 500px;
    }
    h1 {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 2rem;
      line-height: 1.25;
      margin: 2rem 0 3rem 0;
      letter-spacing: 3px;
      color: #fff;
      @media (max-width: 800px) {
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 2px;
      }
    }
    h3 {
      font-weight: 400;
      font-family: "Caveat", cursive;
      color: #fff;
    }
    button {
      background: transparent;
      border: 2px solid #fff;
      padding: 0.5rem 1.5rem;
      text-transform: capitalize;
      letter-spacing: 5px;
      color: #fff;
      font-size: 1.5rem;
      cursor: pointer;
      transition: all 0.2s;
    }
    button:hover {
      background: #fff;
      color: #333;
    }
    @media (min-width: 800px) {
      a {
        font-size: 1.25rem;
        padding: 0.5rem 1.25rem;
      }
      h1 {
        letter-spacing: 5px;
      }
    }
  }
`
