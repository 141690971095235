import React from "react"
import { Link } from "gatsby"
import { NavContainer } from "../../../common"
import logo from "../../../../assets/images/LogoAlbert.png"
import NavbarLinks from "../NavbarLinks"
import { LogoContainer, LinksContainer } from "./styles"

const Navbar = () => {
  return (
    <NavContainer>
      <LogoContainer>
        <img className="img" src={logo} alt="logo bedrijf" as={Link} to="/" />
      </LogoContainer>
      <LinksContainer>
        <NavbarLinks />
      </LinksContainer>
    </NavContainer>
  )
}

export default Navbar
