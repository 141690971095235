import React from "react"

import AboutImg from "../../image/AboutImg"

import { Container } from "components/common"

import { Wrapper, SkillsWrapper, Details, Thumbnail } from "./styles"
import { ContainerFullWidth } from "../../common/Container"

export const About = () => {
  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Details>
          <h1>Albert</h1>
          <p>
            Mijn kennismaking met wijn was in 1975, bij de ouders van mijn
            eerste vriendinnetje. Die eerste liefde ging voorbij en maakte
            plaats voor een nieuwe liefde: wijn! Ik begon steeds meer te lezen
            over wijn, kwam in contact met mensen uit de wijnwereld en ik snoof
            aan grote namen als d’Yquem, Léoville-las-Cases... en ja, ja... Vin
            de Paille! Toen was ik verkocht en behaalde ik vrij rap mijn
            Vinologenbrevet.
          </p>
        </Details>
        <Thumbnail>
          <AboutImg alt="profile picture" filename="Albert.jpg" />
        </Thumbnail>
      </SkillsWrapper>
      <ContainerFullWidth background="#ffa07a">
        <SkillsWrapper as={Container}>
          <Thumbnail>
            <AboutImg alt="profile picture" filename="AlbertAutoBloem.jpg" />
          </Thumbnail>
          <Details>
            <p style={{ color: "#0a4886" }}>
              Via wat omwegen belandde ik bij de Henri Bloem groep, om
              uiteindelijk in 1983 een eigen wijnzaak in het centrum van
              Amersfoort te starten. Kort daarna begon de drang om de wijnboeren
              zelf op te zoeken. Mijn eerste trip in 1985 met Co Hartman van
              Bloem Zwolle naar de Bourgogne werd een memorabele reis, met
              bezoeken aan o.a. Domaine Rousseau en Domaine Engel. Er zouden nog
              vele reizen naar de Bourgogne volgen.
            </p>
          </Details>
        </SkillsWrapper>
      </ContainerFullWidth>
      <SkillsWrapper as={Container}>
        <Details>
          <p>
            Begin jaren '90 begon ik de toen nog onderbelichte ‘nieuwe wereld’
            te bezoeken. Mijn eerste reis was naar Australië, vervolgens bezocht
            ik Zuid-Afrika en tenslotte moesten Amerika en Nieuw-Zeeland er ook
            aan geloven. Dat reizen is altijd mijn ding gebleven: blijven
            zoeken, bezoeken en proeven. Het heeft mij machtig veel vrienden,
            ervaringen en veel goede wijnen opgeleverd. Mijn laatste aanwinsten
            zijn een aantal schitterende champagnes van o.a. Vilmart,
            Pertois-Lebrun en CH Piconnet.
          </p>
        </Details>
        <Thumbnail>
          <AboutImg alt="profile picture" filename="WinkelBuitenkant.jpg" />
        </Thumbnail>
      </SkillsWrapper>
    </Wrapper>
  )
}
