import React from "react"
import { ContainerFullWidth, Container } from "components/common"

import { Wrapper, Details, Title, EmailButton } from "./styles"

export const Contact = () => (
  <ContainerFullWidth
    id="contact"
    background="#ffa07a"
    style={{ paddingBottom: "7rem", textAlign: "center" }}
  >
    <Title>Contact</Title>

    <Wrapper as={Container}>
      <Details>
        <h2>Croon & Co Wijnen </h2>
        <p>
          Albert de Croon <br />
          Blauwgras 89 <br />
          7623GN BORNE
          <br />
          +31 621217610
          <br />
        </p>
      </Details>
      <Details>
        <EmailButton
          target="_blank"
          rel="noopener noreferrer"
          href=" mailto: albert@croonenco.nl "
        >
          Stuur email
        </EmailButton>
      </Details>
      <Details>
        <h2>Openingstijden:</h2>
        <p>Op afspraak geopend.</p>
      </Details>
    </Wrapper>
  </ContainerFullWidth>
)
