import React from "react"
import { Container } from "components/common"
import { Wrapper, Flex, Details } from "./styles"

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <span>
          Copyright © {new Date().getFullYear()} Croon & Co. All Rights Reserved
          | Design by Jeroen Theunissen
        </span>
      </Details>
    </Flex>
  </Wrapper>
)
