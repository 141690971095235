module.exports = {
  defaultTitle: "Croon en Co Wijnen",
  logo: "",
  author: "Albert de Croon",
  url: "https://croonenco.nl",
  legalName: "Croon en Co Wijnen",
  defaultDescription: "De lekkerste wijnen van Amersfoort en omgeving",

  themeColor: "#0a4886",
  backgroundColor: "#ffa07a",
  social: {
    facebook: "",
    twitter: "",
  },
  address: {
    city: "Amersfoort",
    region: "Utrecht",
    country: "Nederland",
    zipCode: "3811 HD",
  },
  contact: {
    email: "albert@croonenco.nl",
    phone: "+31 33 303 3049",
  },
  foundingDate: "2020",
}
