import styled from "styled-components"

export const Wrapper = styled.div`
  a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.2rem;
    margin: 1rem;
    transition: all 0.4s;
    @media (max-width: 700px) {
      margin: 0.4rem;
      font-size: 0.9rem;
      padding: 0;
    }
  }

  a:hover {
    cursor: pointer;
    color: #c2a4a4;
  }
`
