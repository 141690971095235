import React from "react"
import { Layout, SEO } from "components/common"
import { Intro, About, Contact } from "components/landing"

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <About />
    <Contact />
  </Layout>
)
