import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
`

export const LogoContainer = styled.div`
  padding: 0;
  margin: 0;
  z-index: 5;
  width: 20%;
  height: 5rem;

  @media (max-width: 1100px) {
    .img {
      width: 250%;
      margin-left: -2rem;
      display: flex;
    }
  }
  @media (max-width: 700px) {
    .img {
      width: 250%;
      margin-left: -1.8rem;
      display: flex;
    }
  }
`

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  margin-right: 4rem;
  z-index: 5;
  width: 50%;
  height: 100%;
  @media (max-width: 900px) {
    margin: 0;
    margin-right: -1.8rem;
    margin-left: -2rem;
  }
`
