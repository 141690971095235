import React from "react"
import Helmet from "react-helmet"

import {
  url,
  defaultDescription,
  defaultTitle,
  address,
  contact,
  legalName,
  foundingDate,
  logo,
} from "data/config"

export const SEO = ({
  title = defaultTitle,
  description = defaultDescription,
  location = "Amersfoort",
}) => {
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "${legalName}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		}
	
  	}`

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="image" />

      <meta property="og:url" content={`${url}${location}/?ref=croonenco.nl`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" />

      <script type="application/ld+json">{structuredDataOrganization}</script>

      <title>{title}</title>
      <html lang="nl" dir="ltr" />
    </Helmet>
  )
}
