import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: #0a4886;
  text-align: center;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`

export const Details = styled.div`
  h2,
  a,
  span {
    color: #ffffff;
  }
`
