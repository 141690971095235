import styled from "styled-components"

export const NavContainer = styled.div`
  width: 100vw;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 90%;
  background-color: ${props => props.background};
  align-items: center;

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 80%;
  }
`

export const ContainerFullWidth = styled.div`
  margin: 0 auto;
  width: 100vw;
  background-color: ${props => props.background};
`
