import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 2rem 0;
  display: flex;

  justify-content: space-around;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const Details = styled.div`
  width: 40%;
  @media (max-width: 960px) {
    padding-right: unset;
    margin: 3rem;
    width: 100%;
    order: 1;
  }

  h2 {
    font-size: 2rem;
    color: #0a4886;
    margin: 0 0 0.5rem 0;
    padding: 0;
    font-style: italic;
  }

  p {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
    margin: 0;
    padding: 0;
  }
`

export const Title = styled.h1`
  text-align: center;
  padding: 2rem;
  font-size: 3rem;
  color: #0a4886;
`

export const EmailButton = styled.a`
  text-align: center;
  padding: 2rem;
  font-size: 2rem;
  color: #fff;
  background-color: #0a4886;
  transition: all 0.3s;

  &:hover {
    color: #0a4886;
    background-color: #fff;
  }
`
