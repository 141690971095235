import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { Wrapper } from "./styles"

const NavbarLinks = () => {
  return (
    <Wrapper>
      <AnchorLink href="#about">Wie ben ik?</AnchorLink>
      <AnchorLink className="contact" href="#contact">
        Contact
      </AnchorLink>
    </Wrapper>
  )
}

export default NavbarLinks
