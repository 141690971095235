import React from "react"

import { Footer } from "components/theme"
import { Global } from "./styles"
import { Header } from "../../theme/Header"

export const Layout = ({ children }) => {
  return (
    <>
      <Global />
      <Header />
      {children}
      <Footer />
    </>
  )
}
