import styled from "styled-components"

export const Wrapper = styled.div`
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`

export const SkillsWrapper = styled.div`
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`

export const Details = styled.div`
  flex: 1;
  margin: 1rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
    margin: 0.5rem;
  }

  h1 {
    font-size: 2rem;
    color: #333;
    @media (max-width: 700px) {
      font-size: 1.4rem;
    }
  }

  p {
    font-size: 1.7rem;
    font-weight: normal;
    line-height: 1.3;
    color: #333;
    @media (max-width: 700px) {
      font-size: 1.2rem;
    }
  }
`

export const Thumbnail = styled.div`
  flex: 1;
  margin: 1rem;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
  }
`
