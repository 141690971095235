import { createGlobalStyle } from "styled-components"

export const Global = createGlobalStyle`
  html {
    font-family: 'Roboto', Helvetica, sans-serif;
    }
    
    body {
      margin: 0;
      padding: 0;
      font-family: 'Roboto', Helvetica, sans-serif;
      color:#333;
      background-color: #fff;
      transition: .3s all;
      box-sizing: border-box;
    }
    a {
      text-decoration: none;
    }

  
`
